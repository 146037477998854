import * as React from "react";
import { Box } from "@chakra-ui/react";
import type { BoxProps } from "@chakra-ui/react";
import BrandedPinSvg from "../../../public/branded-pin.svg";

interface BrandedPinProps {
  lat?: number;
  lng?: number;
}

export const BrandedPin = (props: BrandedPinProps & BoxProps) => (
  <Box {...props} transform="translate(-20px, -45px)">
    <BrandedPinSvg />
  </Box>
);
