import { ApartmentCardFragment } from "@/generated/graphql";
import { assetsToImages, DEFAULT_CONTENTFUL_IMAGES_QUALITY } from "./assetsToImages";
import { cloudflareLoader } from "./cloudflareLoader";

export function getApartmentImages({
  apartment,
  width = 720,
  quality,
}: {
  apartment: Pick<ApartmentCardFragment, "apartmentMedia" | "buildingMedia" | "apartmentCmsData">;
  width?: number;
  quality?: number;
}) {
  let images = [...(apartment.apartmentMedia ?? []), ...(apartment.buildingMedia ?? [])].map((media) => ({
    baseUrl: media.includes("images.ctfassets") ? `https:${media}` : media,
    url: media.includes("images.ctfassets")
      ? `https:${media}${DEFAULT_CONTENTFUL_IMAGES_QUALITY}`
      : cloudflareLoader({ src: media, width, quality }),
    alt: "",
  }));

  if (images.length > 0) {
    return images;
  }

  images = assetsToImages(apartment.apartmentCmsData?.photos ?? []);

  if (apartment.apartmentCmsData?.heroImage) {
    images = [...assetsToImages([apartment.apartmentCmsData?.heroImage]), ...images];
  }

  return images;
}
