import dayjs from "dayjs";

const locales: Record<string, any> = {
  "da-dk": () => import("dayjs/locale/da"),
  "de-de": () => import("dayjs/locale/de"),
  "en-gb": () => import("dayjs/locale/en"),
  "fr-fr": () => import("dayjs/locale/fr"),
};

function loadLocale(locale: string) {
  locales[locale]().then(() => dayjs.locale(locale));
}

export const formatAvailability = (availability: string, locale = "en-gb"): string => {
  loadLocale(locale);
  const availabilityDate = dayjs(availability).utc();
  const currentDate = dayjs().utc();

  if (availabilityDate.year() !== currentDate.year()) {
    return availabilityDate.format("MMM DD, YYYY");
  } else {
    return availabilityDate.format("MMM DD");
  }
};
