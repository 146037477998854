import { ApartmentCardFragment } from "@/generated/graphql";
import { AMPLITUDE_EVENTS, logEvent } from "@/lib/amplitude";
import { Info } from "@/icons";
import { Box, type BoxProps, Icon, Text, Tooltip, useMediaQuery } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import * as React from "react";

interface BillsIncluded extends BoxProps {
  apartment: Pick<ApartmentCardFragment, "apartmentName" | "apartmentTypeName">;
}

export function BillsIncluded({ apartment, ...rest }: BillsIncluded) {
  const { t } = useTranslation("common");
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [noHover] = useMediaQuery("(@media (hover: none))");

  const onMouseEnter = () => {
    logEvent(AMPLITUDE_EVENTS.billsIncludedHover, { name: apartment.apartmentName });
    setTooltipOpen(true);
  };

  const onMouseLeave = () => {
    setTooltipOpen(false);
  };

  const onClick = () => {
    logEvent(AMPLITUDE_EVENTS.billsIncludedHover, { name: apartment.apartmentName });
    setTooltipOpen(tooltipOpen);
  };

  const apartmentTypeName = `${apartment.apartmentTypeName ?? "Coliving"}`;

  return (
    <Tooltip
      isOpen={tooltipOpen}
      hasArrow
      label={
        <Box>
          <Text
            color="white"
            sx={{
              "& ul": {
                listStyle: "none",
              },
              "& li": {
                color: "gray.400",
              },
            }}
          >
            {t(
              apartmentTypeName.toLowerCase().includes("no service")
                ? "bills-included.without-cleaning"
                : "bills-included.with-cleaning",
            )}
          </Text>
        </Box>
      }
      placement="top"
      bg="gray.900"
      borderRadius="base"
      px={4}
      py={3}
    >
      <Box
        as="button"
        onMouseEnter={noHover ? undefined : onMouseEnter}
        onMouseLeave={noHover ? undefined : onMouseLeave}
        onClick={noHover ? onClick : undefined}
        display="flex"
        flexDirection="row"
        gap={2}
        textStyle="underline"
        color="almond"
        fontWeight="medium"
        alignItems="center"
        zIndex={10}
        cursor="help"
        whiteSpace="nowrap"
        {...rest}
      >
        <Text>{t("common:bills-included.label")}</Text>
        <Icon boxSize={4} as={Info} />
      </Box>
    </Tooltip>
  );
}
