import { Badge, Box, Skeleton } from "@chakra-ui/react";
import * as React from "react";

export interface ApartmentCardLoadingProps {
  badge?: boolean;
  orientation?: "vertical" | "horizontal";
}

export const ApartmentCardLoading = ({ badge, orientation = "vertical" }: ApartmentCardLoadingProps) => {
  const height = { base: "230px", md: "224px", xl: "204px" };
  const width = "100%";

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection={{ base: "column", md: orientation === "vertical" ? "column" : "row" }}
    >
      <Skeleton borderRadius="lg">
        <Box
          height={height}
          width={{ base: width, md: orientation === "vertical" ? width : "300px" }}
          minWidth={{ base: width, md: orientation === "vertical" ? width : "300px" }}
        />
      </Skeleton>
      <Box
        mt={{ base: 4, md: orientation === "vertical" ? 4 : 0 }}
        ml={{ base: 0, md: orientation === "vertical" ? 0 : 4 }}
      >
        <Skeleton h="16px" w="56px" mb={1} />
        <Skeleton h="36px" w="124px" mb={4} />
        <Skeleton h="20px" w="100px" mb={2} />
        <Skeleton h="20px" w="200px" />
      </Box>
      {badge && (
        <Skeleton position="absolute" top={1} left={1}>
          <Badge>Available from Jan 01</Badge>
        </Skeleton>
      )}
    </Box>
  );
};
