import dayjs from "dayjs";
import { Translate } from "next-translate";
import { formatAvailability } from "./formatAvailability";

export function getAvailabilityText({
  available,
  availableFrom,
  locale,
  t,
}: {
  available?: boolean | null;
  availableFrom: string;
  locale: string | undefined;
  t: Translate;
}) {
  let availabilityText = "";
  const currentDate = dayjs(new Date());

  if (available) {
    if (currentDate.isAfter(availableFrom)) {
      availabilityText = t("common:available-now");
    } else {
      const formattedDate = formatAvailability(availableFrom, locale);
      availabilityText = t("common:available-from", { date: formattedDate });
    }
  } else {
    availabilityText = t("common:unavailable");
  }

  return availabilityText;
}
