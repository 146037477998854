import type { BoxProps } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import GoogleMapReact from "google-map-react";
import * as React from "react";

export type MapProps = Pick<
  GoogleMapReact["props"],
  "center" | "options" | "onGoogleApiLoaded" | "yesIWantToUseGoogleMapApiInternals" | "zoom"
> &
  BoxProps & {
    actions?: React.ReactNode;
  };

export const Map = ({
  actions,
  children,
  center,
  options,
  onGoogleApiLoaded,
  yesIWantToUseGoogleMapApiInternals,
  zoom,
  ...rest
}: MapProps) => {
  return (
    <Box h="100vh" w="100%" {...rest}>
      {actions}
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.NEXT_PUBLIC_GMAPS_API_KEY as string }}
        defaultCenter={center}
        defaultZoom={zoom}
        options={options}
        onGoogleApiLoaded={onGoogleApiLoaded}
        yesIWantToUseGoogleMapApiInternals={yesIWantToUseGoogleMapApiInternals}
      >
        {children}
      </GoogleMapReact>
    </Box>
  );
};
