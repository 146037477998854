import { ApartmentTypeFragment } from "@/generated/graphql";
import { Info } from "@/icons";
import { AMPLITUDE_EVENTS, logEvent } from "@/lib/amplitude";
import type { BadgeProps } from "@chakra-ui/react";
import { Badge, Box, Icon, Text, Tooltip, useMediaQuery } from "@chakra-ui/react";
import { gql } from "graphql-tag";
import { useRouter } from "next/router";
import * as React from "react";

export const APARTMENT_TYPE_FRAGMENT = gql`
  fragment ApartmentType on ListingType {
    apartmentTypeName
    apartmentTypeDescription
  }
`;

export interface ApartmentTypeProps extends Omit<BadgeProps, "children"> {
  name: ApartmentTypeFragment["apartmentTypeName"];
  description: ApartmentTypeFragment["apartmentTypeDescription"];
}

export const ApartmentType = ({ name, description, ...rest }: ApartmentTypeProps) => {
  const router = useRouter();
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  // So we don't log both hover and click events if user on desktop hovers and clicks
  const [noHover] = useMediaQuery("(@media (hover: none))");

  const logEventData = { name, isDetailPage: Boolean(router.query.apartment) };

  const onMouseEnter = () => {
    logEvent(AMPLITUDE_EVENTS.apartmentTypeHover, logEventData);
    setTooltipOpen(true);
  };

  const onMouseLeave = () => {
    setTooltipOpen(false);
  };

  const onClick = () => {
    logEvent(AMPLITUDE_EVENTS.apartmentTypeHover, logEventData);
    setTooltipOpen(tooltipOpen);
  };

  const apartmentTypeName = `${name ?? "Coliving"}`;

  return (
    <Tooltip
      isOpen={tooltipOpen}
      hasArrow
      label={
        <Box>
          {description ? (
            <Text
              color="white"
              sx={{
                "& ul": {
                  listStyle: "none",
                },
                "& li": {
                  color: "gray.400",
                },
              }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          ) : null}
        </Box>
      }
      placement="top"
      bg="gray.900"
      borderRadius="base"
      px={4}
      py={3}
    >
      <Badge
        minWidth="max-content"
        whiteSpace="unset"
        zIndex={10}
        bg={apartmentTypeName.toLowerCase().includes("plus") ? "violet.100" : "yellow.200"}
        tabIndex={0}
        color="gray.900"
        display="flex"
        flexDirection="row"
        gap={2}
        fontWeight="medium"
        cursor={description ? "help" : "default"}
        onMouseEnter={noHover || !description ? undefined : onMouseEnter}
        onMouseLeave={noHover || !description ? undefined : onMouseLeave}
        onClick={noHover && description ? onClick : undefined}
        {...rest}
      >
        {apartmentTypeName}
        <Icon boxSize={4} as={Info} />
      </Badge>
    </Tooltip>
  );
};
